<template>
  <header
    class="
      md:pl-16
      w-full
      relative
      shadow-lg
      md:pr-16
      lg:pl-115 lg:pr-115
      z-50
      pt-8
      pb-4
      bg-white
    "
  >
    <div class="md:flex flex-wraps items-center justify-between w-full">
      <div
        class="
          logo-menu
          z-50
          flex
          items-center
          justify-between
          w-full
          md:w-auto
          pr-5
        "
      >
        <router-link to="/" class="logo z-50">
          <svg
            width="171"
            height="63"
            viewBox="0 0 171 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M65.8113 37.512V14.9651H77.1169C78.2121 14.9651 79.2213 15.1476 80.1447 15.5126C81.0895 15.8562 81.8947 16.3394 82.5604 16.9621C83.2261 17.5848 83.7414 18.3364 84.1065 19.2168C84.493 20.0757 84.6862 21.0205 84.6862 22.0512C84.6862 23.0605 84.493 23.9946 84.1065 24.8535C83.7414 25.6909 83.2153 26.421 82.5282 27.0438C81.8625 27.6665 81.068 28.1496 80.1447 28.4932C79.2213 28.8368 78.2121 29.0086 77.1169 29.0086H70.4817V37.512H65.8113ZM76.6016 18.9913H70.4817V25.1434H76.6016C77.6108 25.1434 78.4161 24.8642 79.0173 24.3059C79.64 23.7476 79.9514 23.0068 79.9514 22.0834C79.9514 21.1601 79.64 20.4193 79.0173 19.861C78.4161 19.2812 77.6108 18.9913 76.6016 18.9913Z"
              fill="#009F49"
            />
            <path
              d="M101.803 35.4183C100.772 36.2558 99.7094 36.8678 98.6142 37.2543C97.5406 37.6408 96.3381 37.8341 95.0067 37.8341C93.7398 37.8341 92.548 37.6086 91.4314 37.1576C90.3363 36.7067 89.3807 36.0947 88.5648 35.3217C87.7702 34.5487 87.1368 33.636 86.6644 32.5839C86.2134 31.5102 85.988 30.3614 85.988 29.1374C85.988 27.9349 86.2027 26.8076 86.6322 25.7554C87.0831 24.6817 87.6951 23.7584 88.4681 22.9853C89.2412 22.1908 90.1538 21.5681 91.206 21.1172C92.2796 20.6662 93.4177 20.4407 94.6202 20.4407C95.8012 20.4407 96.8964 20.677 97.9056 21.1494C98.9363 21.6003 99.8167 22.223 100.547 23.0175C101.298 23.812 101.878 24.7676 102.286 25.8842C102.716 26.9793 102.93 28.1711 102.93 29.4595V30.6191H90.4329C90.7336 31.6712 91.3133 32.5194 92.1723 33.1636C93.0312 33.8078 94.0404 34.1299 95.2 34.1299C95.9301 34.1299 96.6172 34.0118 97.2614 33.7756C97.9056 33.5394 98.4532 33.2066 98.9041 32.7771L101.803 35.4183ZM94.5236 24.0805C93.5143 24.0805 92.6447 24.3918 91.9146 25.0145C91.1845 25.6158 90.6799 26.4318 90.4007 27.4625H98.6142C98.3351 26.4747 97.8197 25.6695 97.0682 25.0468C96.3381 24.4026 95.4899 24.0805 94.5236 24.0805Z"
              fill="#009F49"
            />
            <path
              d="M110.369 13.9988V37.512H105.956V14.9651L110.369 13.9988Z"
              fill="#009F49"
            />
            <path
              d="M114.432 37.512V14.9651H125.737C126.833 14.9651 127.842 15.1476 128.765 15.5126C129.71 15.8562 130.515 16.3394 131.181 16.9621C131.847 17.5848 132.362 18.3364 132.727 19.2168C133.113 20.0757 133.307 21.0205 133.307 22.0512C133.307 23.0605 133.113 23.9946 132.727 24.8535C132.362 25.6909 131.836 26.421 131.149 27.0438C130.483 27.6665 129.688 28.1496 128.765 28.4932C127.842 28.8368 126.833 29.0086 125.737 29.0086H119.102V37.512H114.432ZM125.222 18.9913H119.102V25.1434H125.222C126.231 25.1434 127.037 24.8642 127.638 24.3059C128.261 23.7476 128.572 23.0068 128.572 22.0834C128.572 21.1601 128.261 20.4193 127.638 19.861C127.037 19.2812 126.231 18.9913 125.222 18.9913Z"
              fill="#009F49"
            />
            <path
              d="M140.979 37.8018C139.175 37.8018 137.705 37.3294 136.566 36.3846C135.428 35.4183 134.859 34.1729 134.859 32.6483C134.859 31.0593 135.471 29.8138 136.695 28.9119C137.919 28.0101 139.605 27.5591 141.752 27.5591C142.439 27.5591 143.127 27.6128 143.814 27.7202C144.501 27.8275 145.177 27.9886 145.843 28.2033V27.0116C145.843 26.0453 145.542 25.3152 144.941 24.8213C144.34 24.3274 143.459 24.0805 142.3 24.0805C141.591 24.0805 140.818 24.1986 139.981 24.4348C139.165 24.6495 138.231 24.9931 137.178 25.4655L135.568 22.2123C136.878 21.611 138.145 21.1601 139.369 20.8595C140.614 20.5588 141.838 20.4085 143.041 20.4085C145.295 20.4085 147.045 20.9561 148.291 22.0512C149.558 23.1249 150.191 24.6495 150.191 26.625V37.512H145.843V36.3524C145.134 36.8463 144.383 37.2113 143.588 37.4475C142.815 37.6837 141.946 37.8018 140.979 37.8018ZM139.079 32.5516C139.079 33.1958 139.358 33.7112 139.916 34.0977C140.475 34.4628 141.215 34.6453 142.139 34.6453C142.869 34.6453 143.535 34.5594 144.136 34.3876C144.758 34.1943 145.328 33.9259 145.843 33.5824V31.07C145.306 30.8553 144.737 30.7049 144.136 30.6191C143.556 30.5117 142.944 30.458 142.3 30.458C141.291 30.458 140.496 30.6513 139.916 31.0378C139.358 31.4028 139.079 31.9074 139.079 32.5516Z"
              fill="#009F49"
            />
            <path
              d="M158.08 38.285L158.338 37.673L151.799 20.7306H156.663L160.818 32.1651L165.585 20.7306H170.352L162.622 38.8326C161.763 40.851 160.764 42.2898 159.626 43.1487C158.488 44.0076 157.006 44.4371 155.181 44.4371C154.795 44.4371 154.419 44.4156 154.054 44.3727C153.71 44.3512 153.431 44.3082 153.216 44.2438V40.4431C153.431 40.486 153.657 40.5182 153.893 40.5397C154.129 40.5612 154.419 40.5719 154.762 40.5719C155.557 40.5719 156.223 40.3786 156.76 39.9921C157.318 39.6056 157.758 39.0366 158.08 38.285Z"
              fill="#009F49"
            />
            <g filter="url(#filter0_d)">
              <rect
                x="13"
                y="7.60278"
                width="36.8112"
                height="36.8112"
                rx="9.20281"
                stroke="#009F49"
                stroke-width="1.72553"
              />
            </g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.0787 29.5666V36.0458H28.945V29.0065H28.9591L28.9633 25.8067H28.945V25.7105L25.0787 29.5666ZM25.0787 27.7381L28.0492 24.7754L25.0787 24.7715V27.7381ZM25.0787 23.4768L28.945 23.482V20.7139H34.0112C34.8467 20.7139 35.5133 20.9539 36.0111 21.4338C36.5266 21.896 36.7843 22.5093 36.7843 23.2736C36.7843 24.038 36.5266 24.6513 36.0111 25.1135C35.5133 25.5757 34.8467 25.8067 34.0112 25.8067H30.258L30.2537 29.0065H34.4379C35.3445 29.0065 36.1799 28.8642 36.9443 28.5798C37.7087 28.2954 38.3664 27.8954 38.9175 27.3799C39.4863 26.8644 39.9218 26.26 40.224 25.5668C40.544 24.8557 40.704 24.0825 40.704 23.247C40.704 22.3937 40.544 21.6116 40.224 20.9005C39.9218 20.1717 39.4952 19.5495 38.9441 19.034C38.3931 18.5185 37.7265 18.1186 36.9443 17.8342C36.1799 17.532 35.3445 17.3809 34.4379 17.3809H25.0787V23.4768Z"
              fill="#009F49"
            />
            <defs>
              <filter
                id="filter0_d"
                x="0.633697"
                y="0.988235"
                width="61.5438"
                height="61.5438"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="5.75176" />
                <feGaussianBlur stdDeviation="5.75176" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </router-link>
        <div class="menu-wrapper z-50" @click="isOpen = !isOpen">
          <svg
            v-if="!isOpen"
            xmlns="http://www.w3.org/2000/svg"
            class="md:hidden z-50"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
          </svg>
          <svg
            v-if="isOpen"
            xmlns="http://www.w3.org/2000/svg"
            class="md:hidden z-50"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
            />
          </svg>
        </div>
      </div>
      <div
        class="
          link-button
          hidden
          w-full
          md:flex md:flex-row
          flex-col
          mt-10
          md:mt-0 md:justify-end
          pb-6
          md:pb-0
        "
      >
        <nav
          class="
            flex
            md:flex-row
            flex-col
            items-center
            justify-center
            font-medium
            space-y-8
            md:space-y-0 md:space-x-9
          "
        >
          <router-link class="nav-link" to="/">Home</router-link>
          <router-link class="nav-link" to="features">Features</router-link>
          <router-link class="nav-link" to="about">About us</router-link>
          <router-link class="nav-link" to="developers">Developers</router-link>
          <router-link class="nav-link" to="compliance">Compliance</router-link>
        </nav>

        <div
          class="
            button
            flex
            justify-center
            md:justify-start
            items-center
            mt-8
            md:mt-0 md:ml-10
            w-full
            md:w-auto
            space-x-9
            md:space-x-5
          "
        >
          <a href="https://merchant.pelpay.ng/login" target="_blank">
            <button
              class="
                bg-white
                border
                md:border-0
                transition-all
                hover:bg-pelpayblue hover:text-white
                duration-500
                ease-in-out
                px-8
                py-4
                rounded-md
                leading-160
                tracking-0.02em
                font-semibold
                text-primarygreen
              "
            >
              Login
            </button>
          </a>

          <a href="https://merchant.pelpay.ng/onboading" target="_blank">
            <button
              class="
                bg-primarygreen
                transition-all
                duration-500
                ease-in-out
                hover:bg-pelpayblue
                px-8
                py-4
                rounded-md
                leading-160
                tracking-0.02em
                font-semibold
                text-white
              "
            >
              Sign up
            </button>
          </a>
        </div>
      </div>

      <div
        v-if="isOpen"
        :class="{ open: isOpen }"
        class="
          link-button
          z-10
          transform
          bg-white
          h-screen
          fixed
          top-0
          mobile-menu
          overflow-hidden
          w-full
          flex
          md:hidden md:flex-row
          flex-col
          pt-32
          md:mt-0 md:justify-end
          pb-6
          md:pb-0
        "
      >
        <nav
          class="
            flex
            md:flex-row
            flex-col
            items-center
            justify-center
            font-medium
            space-y-8
            md:space-y-0 md:space-x-9
          "
        >
          <router-link class="nav-link" to="/">Home</router-link>
          <router-link class="nav-link" to="features">Features</router-link>
          <router-link class="nav-link" to="about">About us</router-link>
          <router-link class="nav-link" to="developers">Developers</router-link>
          <router-link class="nav-link" to="compliance">Compliance</router-link>
        </nav>

        <div
          class="
            button
            flex
            justify-center
            md:justify-start
            items-center
            mt-8
            md:mt-0 md:ml-10
            w-full
            md:w-auto
            space-x-9
            md:space-x-5
          "
        >
          <a href="https://merchant.pelpay.ng/login" target="_blank">
            <button
              class="
                bg-white
                border
                md:border-0
                transition-all
                hover:bg-pelpayblue hover:text-white
                duration-500
                ease-in-out
                px-8
                py-4
                rounded-md
                leading-160
                tracking-0.02em
                font-semibold
                text-primarygreen
              "
            >
              Login
            </button>
          </a>

          <a href="https://merchant.pelpay.ng/onboading" target="_blank">
            <button
              class="
                bg-primarygreen
                transition-all
                duration-500
                ease-in-out
                hover:bg-pelpayblue
                px-8
                py-4
                rounded-md
                leading-160
                tracking-0.02em
                font-semibold
                text-white
              "
            >
              Sign up
            </button>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  components: {},
  watch: {
    $route() {
      this.isOpen = false;
    },
  },
  methods: {
    show() {
      console.log("working");
    },
  },
};
</script>

<style lang="scss">
.mobile-menu.open {
  position: fixed;
  overflow: hidden;
  transform: translateX(1px);
  font-weight: 900 !important;
  transition: all 5s ease-in-out !important;
}

.mobile-menu {
  transform: translateX(900px);
  transition: all 5s ease-in-out !important;
}
</style>
