<template>
  <section class="bg-white relative md:pl-115 md:pr-115 md:pt-20 pb-3">
    <div
      class="
        footer-design
        relative
        bg-pelpayblue
        flex flex-col
        justify-center
        items-center
        pt-16
        pb-12
        rounded-30px rounded-bl-none
        px-5
        md:px-0
      "
    >
      <svg
        class="absolute hidden md:block left-0 bottom-0"
        width="75"
        height="228"
        viewBox="0 0 75 228"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 151.164C19.8912 151.164 38.9678 143.21 53.033 129.051C67.0982 114.892 75 95.6879 75 75.6641C75 55.6402 67.0982 36.4365 53.033 22.2775C38.9678 8.11852 19.8913 0.164062 2.27366e-05 0.164062L7.57887e-06 75.6641L0 151.164Z"
          fill="#FFD471"
        />
        <path
          d="M0 112.164C9.48456 112.164 18.5807 108.371 25.2873 101.62C31.9939 94.8686 35.7616 85.7119 35.7616 76.1641C35.7616 66.6163 31.9939 57.4595 25.2873 50.7082C18.5807 43.9569 9.48457 40.1641 1.08413e-05 40.1641L3.61376e-06 76.1641L0 112.164Z"
          fill="#041A3E"
        />
        <path
          d="M0 302.164C19.8912 302.164 38.9678 294.21 53.033 280.051C67.0982 265.892 75 246.688 75 226.664C75 206.64 67.0982 187.437 53.033 173.278C38.9678 159.119 19.8913 151.164 2.27366e-05 151.164L7.57887e-06 226.664L0 302.164Z"
          fill="#D5C6FF"
        />
      </svg>

      <svg
        class="absolute hidden md:block right-0 bottom-0"
        width="130"
        height="228"
        viewBox="0 0 130 228"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M130 151.164C110.053 151.164 90.9227 143.21 76.818 129.051C62.7132 114.892 54.7893 95.6879 54.7893 75.6641C54.7893 55.6402 62.7132 36.4365 76.818 22.2775C90.9227 8.11852 110.053 0.164062 130 0.164062L130 75.6641L130 151.164Z"
          fill="#FFD471"
        />
        <path
          d="M-0.000244141 227.164C19.9469 227.164 39.077 219.21 53.1818 205.051C67.2865 190.892 75.2105 171.688 75.2105 151.664C75.2105 131.64 67.2865 112.437 53.1818 98.2775C39.077 84.1185 19.9469 76.1641 -0.00022134 76.1641L-0.00023654 151.664L-0.000244141 227.164Z"
          fill="#0AD366"
        />
        <path
          d="M-0.000244141 188.164C9.51097 188.164 18.6326 184.371 25.3581 177.62C32.0835 170.869 35.8618 161.712 35.8618 152.164C35.8618 142.616 32.0835 133.46 25.3581 126.708C18.6326 119.957 9.51098 116.164 -0.000233269 116.164L-0.000240517 152.164L-0.000244141 188.164Z"
          fill="#041A3E"
        />
      </svg>
      <h3
        data-aos="fade-up"
        data-aos-duration="1000"
        class="
          font-bold
          text-3xl
          md:text-4xl
          text-white
          leading-124
          text-center
        "
      >
        We care whenever you need it
      </h3>

      <div
        class="
          button-group
          flex
          md:flex-row
          flex-col
          items-center
          md:space-x-5
          mt-12
        "
      >
        <router-link to="/">
          <button
            data-aos="fade-up"
            data-aos-duration="2000"
            class="
              bg-white
              text-base
              px-12
              md:px-10
              py-4
              rounded-md
              leading-160
              tracking-0.02em
              font-bold
              text-primarygreen
              hover:text-white hover:bg-primarygreen
            "
          >
            Book a session
          </button>
        </router-link>

        <router-link to="/">
          <button
            data-aos="fade-up"
            data-aos-duration="2000"
            class="
              bg-transparent
              border
              shadow-whitebutton
              px-16
              md:px-10
              text-base
              py-4
              rounded-md
              leading-160
              tracking-0.02em
              font-bold
              mt-4
              md:mt-0
              hover:bg-white hover:text-pelpayblue
              text-white
            "
          >
            Contact Us
          </button>
        </router-link>
      </div>
    </div>

    <div
      class="
        footer-link-wrapper
        px-5
        flex
        md:flex-row
        flex-col
        justify-between
        mt-14
        border-b
        pb-3
        mb-5
      "
    >
      <div class="logo-section">
        <router-link class="" to="/">
          <svg
            width="168"
            height="63"
            viewBox="0 0 168 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M64.9514 37.0732V14.5264H76.0729C77.1502 14.5264 78.143 14.7089 79.0513 15.0739C79.9808 15.4175 80.7729 15.9006 81.4277 16.5234C82.0826 17.1461 82.5895 17.8976 82.9486 18.7781C83.3289 19.637 83.519 20.5818 83.519 21.6125C83.519 22.6218 83.3289 23.5558 82.9486 24.4148C82.5895 25.2522 82.072 25.9823 81.3961 26.605C80.7412 27.2278 79.9597 27.7109 79.0513 28.0545C78.143 28.3981 77.1502 28.5698 76.0729 28.5698H69.5458V37.0732H64.9514ZM75.566 18.5526H69.5458V24.7047H75.566C76.5588 24.7047 77.3509 24.4255 77.9424 23.8672C78.5549 23.3089 78.8612 22.5681 78.8612 21.6447C78.8612 20.7214 78.5549 19.9806 77.9424 19.4222C77.3509 18.8425 76.5588 18.5526 75.566 18.5526Z"
              fill="#009F49"
            />
            <path
              d="M100.357 34.9796C99.343 35.8171 98.2974 36.429 97.2201 36.8156C96.1639 37.2021 94.981 37.3953 93.6714 37.3953C92.4251 37.3953 91.2527 37.1699 90.1543 36.7189C89.077 36.268 88.137 35.656 87.3343 34.883C86.5527 34.1099 85.9296 33.1973 85.4649 32.1451C85.0213 31.0715 84.7995 29.9227 84.7995 28.6987C84.7995 27.4962 85.0107 26.3688 85.4332 25.3166C85.8768 24.243 86.4788 23.3196 87.2393 22.5466C87.9997 21.7521 88.8975 21.1294 89.9325 20.6784C90.9887 20.2275 92.1082 20.002 93.2911 20.002C94.4529 20.002 95.5302 20.2382 96.523 20.7106C97.537 21.1616 98.403 21.7843 99.1212 22.5788C99.8605 23.3733 100.431 24.3289 100.832 25.4455C101.255 26.5406 101.466 27.7324 101.466 29.0208V30.1803H89.1721C89.4678 31.2325 90.0381 32.0807 90.8831 32.7249C91.728 33.3691 92.7208 33.6912 93.8615 33.6912C94.5797 33.6912 95.2556 33.5731 95.8893 33.3369C96.523 33.1007 97.0617 32.7679 97.5053 32.3384L100.357 34.9796ZM93.1961 23.6417C92.2033 23.6417 91.3478 23.9531 90.6296 24.5758C89.9114 25.1771 89.415 25.9931 89.1404 27.0238H97.2201C96.9455 26.036 96.4385 25.2308 95.6992 24.608C94.981 23.9638 94.1466 23.6417 93.1961 23.6417Z"
              fill="#009F49"
            />
            <path
              d="M108.783 13.5601V37.0732H104.442V14.5264L108.783 13.5601Z"
              fill="#009F49"
            />
            <path
              d="M112.78 37.0732V14.5264H123.902C124.979 14.5264 125.972 14.7089 126.88 15.0739C127.809 15.4175 128.602 15.9006 129.256 16.5234C129.911 17.1461 130.418 17.8976 130.777 18.7781C131.157 19.637 131.348 20.5818 131.348 21.6125C131.348 22.6218 131.157 23.5558 130.777 24.4148C130.418 25.2522 129.901 25.9823 129.225 26.605C128.57 27.2278 127.788 27.7109 126.88 28.0545C125.972 28.3981 124.979 28.5698 123.902 28.5698H117.374V37.0732H112.78ZM123.395 18.5526H117.374V24.7047H123.395C124.387 24.7047 125.18 24.4255 125.771 23.8672C126.384 23.3089 126.69 22.5681 126.69 21.6447C126.69 20.7214 126.384 19.9806 125.771 19.4222C125.18 18.8425 124.387 18.5526 123.395 18.5526Z"
              fill="#009F49"
            />
            <path
              d="M138.895 37.3631C137.121 37.3631 135.674 36.8907 134.554 35.9459C133.435 34.9796 132.875 33.7341 132.875 32.2096C132.875 30.6205 133.477 29.3751 134.681 28.4732C135.885 27.5713 137.543 27.1204 139.656 27.1204C140.331 27.1204 141.007 27.1741 141.683 27.2814C142.359 27.3888 143.025 27.5499 143.68 27.7646V26.5728C143.68 25.6065 143.384 24.8764 142.792 24.3826C142.201 23.8887 141.335 23.6417 140.194 23.6417C139.497 23.6417 138.737 23.7598 137.913 23.996C137.11 24.2108 136.191 24.5543 135.156 25.0268L133.572 21.7736C134.861 21.1723 136.107 20.7214 137.311 20.4208C138.536 20.1201 139.74 19.9698 140.923 19.9698C143.141 19.9698 144.862 20.5174 146.088 21.6125C147.334 22.6862 147.957 24.2108 147.957 26.1863V37.0732H143.68V35.9137C142.982 36.4076 142.243 36.7726 141.462 37.0088C140.701 37.245 139.846 37.3631 138.895 37.3631ZM137.026 32.1129C137.026 32.7571 137.3 33.2725 137.849 33.659C138.399 34.024 139.127 34.2066 140.036 34.2066C140.754 34.2066 141.409 34.1207 142 33.9489C142.613 33.7556 143.173 33.4872 143.68 33.1436V30.6313C143.151 30.4165 142.592 30.2662 142 30.1803C141.43 30.073 140.828 30.0193 140.194 30.0193C139.201 30.0193 138.42 30.2125 137.849 30.5991C137.3 30.9641 137.026 31.4687 137.026 32.1129Z"
              fill="#009F49"
            />
            <path
              d="M155.717 37.8463L155.971 37.2343L149.539 20.2919H154.323L158.411 31.7264L163.1 20.2919H167.79L160.185 38.3938C159.34 40.4123 158.358 41.851 157.238 42.71C156.119 43.5689 154.661 43.9984 152.866 43.9984C152.486 43.9984 152.116 43.9769 151.757 43.9339C151.419 43.9125 151.144 43.8695 150.933 43.8051V40.0043C151.144 40.0473 151.366 40.0795 151.598 40.101C151.831 40.1224 152.116 40.1332 152.454 40.1332C153.235 40.1332 153.89 39.9399 154.418 39.5534C154.968 39.1669 155.401 38.5978 155.717 37.8463Z"
              fill="#009F49"
            />
            <g filter="url(#filter0_d)">
              <rect
                x="13"
                y="7.16406"
                width="36.2117"
                height="36.8112"
                rx="9.20281"
                stroke="#009F49"
                stroke-width="1.72553"
              />
            </g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24.8818 29.0453V35.6071H28.6852V30.4248C28.6309 30.3288 28.5999 30.2174 28.6 30.0987L28.6063 25.2665L24.8818 29.0453ZM24.8818 27.2012L27.7 24.342L24.8818 24.3382V27.2012ZM24.8818 23.0325L28.6852 23.0377V20.2752H33.6689C34.4907 20.2752 35.1465 20.5151 35.6361 20.9951C36.1432 21.4573 36.3968 22.0706 36.3968 22.8349C36.3968 23.5993 36.1432 24.2126 35.6361 24.6748C35.1465 25.1369 34.4907 25.368 33.6689 25.368H29.8897L29.8856 28.5677H34.0885C34.9804 28.5677 35.8022 28.4255 36.5542 28.1411C37.3061 27.8567 37.9531 27.4567 38.4952 26.9412C39.0547 26.4257 39.4832 25.8213 39.7804 25.128C40.0952 24.417 40.2526 23.6437 40.2526 22.8083C40.2526 21.955 40.0952 21.1729 39.7804 20.4618C39.4832 19.733 39.0635 19.1108 38.5214 18.5953C37.9793 18.0798 37.3236 17.6798 36.5542 17.3954C35.8022 17.0932 34.9804 16.9421 34.0885 16.9421H24.8818V23.0325Z"
              fill="#009F49"
            />
            <defs>
              <filter
                id="filter0_d"
                x="0.633697"
                y="0.549514"
                width="60.9442"
                height="61.5438"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="5.75176" />
                <feGaussianBlur stdDeviation="5.75176" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </router-link>
        <a
          class="
            text-base
            block
            hover:text-primarygreen
            ml-2
            text-primarydark
            leading-160
            mb-2
          "
          href="mailto:Info@pelpay.africa"
          >Info@pelpay.africa</a
        >
        <a
          class="
            text-base
            block
            hover:text-primarygreen
            ml-2
            text-primarydark
            leading-160
            mb-2
          "
          href="tel:(704) 555-0127"
          >(704) 555-0127</a
        >
      </div>

      <div class="footer-links grid grid-cols-1s grid-cols-3 md:gap-x-32">
        <div class="company mt-4 md:mt-0">
          <h2 class="text-primarydark font-medium text-lg mb-4">Company</h2>
          <router-link
            class="
              block
              text-pelpaygray100
              mb-4
              hover:text-primarygreen
              text-lg
              leading-27px
            "
            to="about"
            >About Us</router-link
          >
          <router-link
            class="
              block
              text-pelpaygray100
              mb-4
              hover:text-primarygreen
              text-lg
              leading-27px
            "
            to="/"
            >Blog</router-link
          >
          <router-link
            class="
              block
              text-pelpaygray100
              mb-4
              hover:text-primarygreen
              text-lg
              leading-27px
            "
            to="compliance"
            >Compliance</router-link
          >
        </div>
        <div class="company mt-4 md:mt-0 text-center md:text-left">
          <h2 class="text-primarydark font-medium text-lg mb-4">Resources</h2>
          <router-link
            class="
              block
              text-pelpaygray100
              mb-4
              hover:text-primarygreen
              text-lg
              leading-27px
            "
            to="/"
            >FAQ</router-link
          >
          <router-link
            class="
              block
              text-pelpaygray100
              mb-4
              hover:text-primarygreen
              text-lg
              leading-27px
            "
            to="/"
            >Blog</router-link
          >
          <router-link
            class="
              block
              text-pelpaygray100
              mb-4
              hover:text-primarygreen
              text-lg
              leading-27px
            "
            to="developers"
            >Developers</router-link
          >
        </div>
        <div class="company mt-4 md:mt-0 text-right md:text-left">
          <h2 class="text-primarydark font-medium text-lg mb-4">Support</h2>
          <router-link
            class="
              block
              text-pelpaygray100
              mb-4
              hover:text-primarygreen
              text-lg
              leading-27px
            "
            to="/"
            >Policy</router-link
          >
          <router-link
            class="
              block
              text-pelpaygray100
              mb-4
              hover:text-primarygreen
              text-lg
              leading-27px
            "
            to="/"
            >Terms of Use</router-link
          >
        </div>
      </div>
    </div>
    <div class="copyright text-center">
      <span class="text-center text-primarydark text-base leading-160"
        >© 2021 PelPay, Inc. All rights reserved.
      </span>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
