<template>
  <section
    class="
      h-auto
      lg:h-auto
      relative
      pb-20
      bg-pelpayblue100
      flex
      md:flex-row
      flex-col
      justify-between
      home-hero
      pr-5
      pl-5
      md:pl-115
      w-full
    "
  >
    <div class="text-wrapper md:w-6/12">
      <div class="text-content pt-16 md:pt-103px">
        <h2
          class="
            font-semibold
            md:max-w-38rem
            text-5xl
            md:text-64px
            leading-76px
            text-white
          "
        >
          The <span class="text-primarygreen">API suite</span> to build amazing
          payments products
        </h2>
        <p
          class="
            text-white
            opacity-80
            text-xl
            leading-8
            font-normal
            mt-42px
            max-w-30rem
          "
        >
          Power up your payments with comprehensive APIs that help you create
          sophisticated payment flows and gain operational efficiencies.
        </p>
      </div>
      <div class="button-group flex max-w-xs items-center space-x-5 mt-11">
        <router-link to="/">
          <button
            class="
              bg-primarygreen
              hover:bg-white
              transition-all
              duration-500
              ease-in-out
              text-lg
              px-10
              py-4
              rounded-md
              leading-160
              tracking-0.02em
              font-medium
              text-white
              hover:text-primarygreen
            "
          >
            View Documentation
          </button>
        </router-link>
      </div>
    </div>

    <div class="dev-svg hidden  absolute md:right-0 md:mr-5 md:bottom-0 hidden md:flex justify-end items-end w-6/12">
      <svg
        class=""
        width="689"
        height="549"
        viewBox="0 0 689 549"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="144" width="451.62" height="547.952" fill="#08763A" />
        <path
          opacity="0.44"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M52.0604 389.425C51.4746 390.01 51.4746 390.96 52.0604 391.546L58.4244 397.91C59.0102 398.496 59.9599 398.496 60.5457 397.91L66.9097 391.546C67.4955 390.96 67.4955 390.01 66.9097 389.425L60.5457 383.061C59.9599 382.475 59.0102 382.475 58.4244 383.061L52.0604 389.425ZM76.0604 389.425C75.4746 390.01 75.4746 390.96 76.0604 391.546L82.4244 397.91C83.0102 398.496 83.9599 398.496 84.5457 397.91L90.9097 391.546C91.4955 390.96 91.4955 390.01 90.9097 389.425L84.5457 383.061C83.9599 382.475 83.0102 382.475 82.4244 383.061L76.0604 389.425Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M64.0604 377.425C63.4746 378.01 63.4746 378.96 64.0604 379.546L70.4244 385.91C71.0102 386.496 71.9599 386.496 72.5457 385.91L78.9097 379.546C79.4955 378.96 79.4955 378.01 78.9097 377.425L72.5457 371.061C71.9599 370.475 71.0102 370.475 70.4244 371.061L64.0604 377.425ZM64.0604 401.425C63.4746 402.01 63.4746 402.96 64.0604 403.546L70.4244 409.91C71.0102 410.496 71.9599 410.496 72.5457 409.91L78.9097 403.546C79.4955 402.96 79.4955 402.01 78.9097 401.425L72.5457 395.061C71.9599 394.475 71.0102 394.475 70.4244 395.061L64.0604 401.425Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M324.99 233.901C328.358 237.071 328.519 242.372 325.349 245.74L297.251 275.594L325.349 305.448C328.519 308.816 328.358 314.116 324.99 317.286C321.622 320.456 316.321 320.296 313.151 316.927L279.651 281.334C276.616 278.109 276.616 273.079 279.651 269.854L313.151 234.26C316.321 230.892 321.622 230.731 324.99 233.901ZM414.01 233.901C410.642 237.071 410.481 242.372 413.651 245.74L441.749 275.594L413.651 305.448C410.481 308.816 410.642 314.116 414.01 317.286C417.378 320.456 422.678 320.296 425.849 316.927L459.349 281.334C462.384 278.109 462.384 273.079 459.349 269.854L425.849 234.26C422.678 230.892 417.378 230.731 414.01 233.901Z"
          fill="white"
        />
        <rect
          opacity="0.44"
          x="378.752"
          y="206.615"
          width="16.75"
          height="134"
          rx="4.1875"
          transform="rotate(15 378.752 206.615)"
          fill="white"
        />
        <rect x="143.981" width="119.12" height="122.228" fill="#009F49" />
        <rect x="493.054" width="102.547" height="40.3972" fill="#22542D" />
        <rect
          x="455.227"
          y="104.082"
          width="37.3256"
          height="37.3256"
          stroke="white"
        />
        <rect
          x="493.055"
          y="103.582"
          width="38.3256"
          height="38.3256"
          fill="#2CB36A"
        />
        <rect
          x="531.379"
          y="103.582"
          width="38.3256"
          height="38.3256"
          fill="#064A25"
        />
        <rect
          x="493.054"
          y="141.906"
          width="38.3256"
          height="38.3256"
          fill="white"
        />
        <rect
          x="595.695"
          y="105.504"
          width="27.4352"
          height="168.352"
          fill="#224254"
        />
        <rect
          x="82.8652"
          y="61.1172"
          width="61.1138"
          height="61.1138"
          fill="#2CB36A"
        />
        <rect
          x="49.7188"
          y="509.625"
          width="36.2539"
          height="38.3256"
          fill="#EFE6E8"
        />
        <rect
          y="319"
          width="143.98"
          height="142.944"
          fill="#009F49"
          fill-opacity="0.23"
        />
        <rect x="595" y="273" width="93.2244" height="93.2244" fill="#2CB36A" />
        <rect
          x="565.766"
          y="488.844"
          width="29.9293"
          height="29.9293"
          fill="#3D8050"
        />
        <rect
          x="505.906"
          y="488.844"
          width="29.9293"
          height="29.9293"
          fill="#2CB36A"
        />
        <rect
          x="535.836"
          y="518.774"
          width="29.9293"
          height="29.9293"
          fill="#224254"
        />
        <rect
          x="535.836"
          y="458.914"
          width="29.9293"
          height="29.9293"
          fill="white"
        />
        <rect
          x="493.054"
          y="40.3977"
          width="102.547"
          height="63.1854"
          fill="#C5FFDF"
        />
        <g opacity="0.6">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M213.086 71.9142L202 60.8284V45C202 43.8954 202.895 43 204 43C205.105 43 206 43.8954 206 45V59.1716L215.914 69.0858L221.146 63.8536C221.461 63.5386 222 63.7617 222 64.2071V76.5C222 76.7761 221.776 77 221.5 77H209.207C208.762 77 208.539 76.4614 208.854 76.1464L213.086 71.9142Z"
            fill="white"
          />
          <path
            opacity="0.44"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M197.708 63.3264L200.292 66.38L194.49 71.2896L199.347 76.1465C199.662 76.4614 199.439 77 198.993 77H186.558C186.282 77 186.058 76.7762 186.058 76.5V64.065C186.058 63.6195 186.597 63.3965 186.912 63.7114L191.652 68.4514L197.708 63.3264Z"
            fill="white"
          />
        </g>
        <g opacity="0.6">
          <path
            opacity="0.44"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M632.5 314C631.948 314 631.5 314.448 631.5 315V325C631.5 325.552 631.948 326 632.5 326H633.5C634.052 326 634.5 325.552 634.5 325V321.5H646.5C649.814 321.5 652.5 318.814 652.5 315.5V314H649.5V315.5C649.5 317.157 648.157 318.5 646.5 318.5H634.5V315C634.5 314.448 634.052 314 633.5 314H632.5Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M627 330.5C627 333.814 629.686 336.5 633 336.5C636.314 336.5 639 333.814 639 330.5C639 327.186 636.314 324.5 633 324.5C629.686 324.5 627 327.186 627 330.5ZM636 330.5C636 332.157 634.657 333.5 633 333.5C631.343 333.5 630 332.157 630 330.5C630 328.843 631.343 327.5 633 327.5C634.657 327.5 636 328.843 636 330.5Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M645 309.5C645 312.814 647.686 315.5 651 315.5C654.314 315.5 657 312.814 657 309.5C657 306.186 654.314 303.5 651 303.5C647.686 303.5 645 306.186 645 309.5ZM654 309.5C654 311.157 652.657 312.5 651 312.5C649.343 312.5 648 311.157 648 309.5C648 307.843 649.343 306.5 651 306.5C652.657 306.5 654 307.843 654 309.5Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M627 309.5C627 312.814 629.686 315.5 633 315.5C636.314 315.5 639 312.814 639 309.5C639 306.186 636.314 303.5 633 303.5C629.686 303.5 627 306.186 627 309.5ZM636 309.5C636 311.157 634.657 312.5 633 312.5C631.343 312.5 630 311.157 630 309.5C630 307.843 631.343 306.5 633 306.5C634.657 306.5 636 307.843 636 309.5Z"
            fill="white"
          />
        </g>
      </svg>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss"></style>
