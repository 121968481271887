<template>
  <section>
    <DevelopersHero />
    <DeveloperFeatures />
  </section>
</template>

<script>
import DeveloperFeatures from "../components/Developers/DeveloperFeatures.vue";
import DevelopersHero from "../components/Developers/DevelopersHero.vue";
export default {
  components: { DevelopersHero, DeveloperFeatures },
};
</script>

<style></style>
