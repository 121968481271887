<template>
  <section class="bg-white w-full pl-5 pr-5 md:pl-115 md:pr-115 pb-24">
    <div
      class="
        sections
        flex
        md:flex-row
        flex-col
        justify-between
        pt-16
        mt:pt-141
        md:space-x-10
      "
    >
      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        class="image-wrapper w-full"
      >
        <img src="../../assets/images/AnalyticsGraphic.png" alt="" />
      </div>

      <div
        data-aos="fade-left"
        data-aos-duration="1000"
        class="section-content w-full"
      >
        <div class="top-span flex items-center md:mb-9">
          <svg
            width="72"
            height="4"
            viewBox="0 0 72 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.382324"
              y="0.849731"
              width="71.0851"
              height="2.18723"
              fill="#D1D0D6"
            />
          </svg>

          <span class="text-lg ml-9 text-pelpaygray500 font-bold leading-27px"
            >Developers</span
          >
        </div>

        <div class="text-contents">
          <div class="top-header w-full pt-6 bg-cover">
            <h3
              class="
                font-bold
                text-4xl
                md:text-5xl
                text-black
                md:leading-56px
                max-w-lg
                text-left
              "
            >
              Experience how our platform empowers developers
            </h3>
            <p
              class="
                text-pelpaygray200
                leading-30px
                text-lg
                font-normal
                text-left
                max-w-lg
                mt-6
              "
            >
              Spin up your own private sandbox and start developing in minutes.
              Explore our endpoints, make API requests, and quickly prototype
              your product.
            </p>
          </div>
        </div>

        <div class="button flex items-center space-x-5 mt-8">
          <router-link to="/">
            <button
              class="
                bg-primarygreen
                transition-all
                duration-500
                text-lg
                ease-in-out
                hover:bg-pelpayblue
                px-8
                py-4
                rounded-md
                leading-160
                tracking-0.02em
                font-medium
                text-white
              "
            >
              Vew Documenation
            </button>
          </router-link>
        </div>
      </div>
    </div>

    <div
      class="sections flex md:flex-row flex-col justify-between pt-16 md:pt-141"
    >
      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        class="section-content w-full"
      >
        <div class="text-contents">
          <div class="top-header w-full pt-6 bg-cover">
            <h3
              class="
                font-bold
                text-4xl
                md:text-5xl
                text-black
                md:leading-56px
                max-w-sm
                text-left
              "
            >
              More flexibility, less complexity
            </h3>
            <p
              class="
                text-pelpaygray200
                leading-30px
                text-xl
                font-normal
                text-left
                max-w-30.5rem
                mt-6
              "
            >
              Your customers’ payment experience is fully automated so payments
              take place with minimal effort. Monitor transaction insights such
              as sales, customer information, success rates, and more!
            </p>
          </div>
        </div>

        <div class="list items-center text-pelpaygray600 mt-8">
          <div class="list-wrapper mb-5 flex items-center space-x-5">
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 7.00012L5.5 10.5001L14.5 1.50012"
                stroke="#009F49"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
            <span class="text-lg leading-6"
              >Get alerts for important updates
            </span>
          </div>

          <div class="list-wrapper mb-5 flex items-center space-x-5">
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 7.00012L5.5 10.5001L14.5 1.50012"
                stroke="#009F49"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
            <span class="text-lg leading-6"
              >Setup payment links and Look up transactions
            </span>
          </div>

          <div class="list-wrapper mb-5 flex items-center space-x-5">
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 7.00012L5.5 10.5001L14.5 1.50012"
                stroke="#009F49"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
            <span class="text-lg leading-6"
              >Start with done-for-you account setup
            </span>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-left"
        data-aos-duration="1000"
        class="image-wrapper w-full -mt-20"
      >
        <img src="../../assets/images/graph.png" alt="" />
      </div>
    </div>

    <div
      class="
        sections
        flex
        md:flex-row
        flex-col-reverse
        justify-between
        space-x-10s
      "
    >
      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        class="image-wrapper w-full"
      >
        <img src="../../assets/images/paymentcard.png" alt="" />
      </div>

      <div
        data-aos="fade-left"
        data-aos-duration="1000"
        class="section-content w-full md:pt-48"
      >
        <div class="text-contents">
          <div class="top-header w-full pt-6 bg-cover">
            <h3
              class="
                font-bold
                text-4xl
                md:text-5xl
                text-black
                md:leading-56px
                max-w-lg
                text-left
              "
            >
              Create automated payment flows
            </h3>
            <p
              class="
                text-pelpaygray200
                leading-30px
                text-md
                font-normal
                text-left
                max-w-27.5rem
                mt-6
              "
            >
              Churn Buster takes the right action at the right times to preserve
              customer relationships, elevate your brand, and retain more
              customers long-term.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="sections flex md:flex-row flex-col justify-between md:pt-141">
      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        class="section-content w-full"
      >
        <div class="text-contents">
          <div class="top-header w-full pt-6 bg-cover">
            <h3
              class="
                font-bold
                text-4xl
                md:text-5xl
                text-black
                md:leading-56px
                max-w-2xl
                text-left
              "
            >
              Fraud Protection and Transaction Monitoring
            </h3>
            <p
              class="
                text-pelpaygray200
                leading-30px
                text-xl
                font-normal
                text-left
                max-w-30.5rem
                mt-6
              "
            >
              PelPay combination of automated and manual fraud systems protect
              you from fraudulent transactions and associated chargeback claims.
            </p>
          </div>
        </div>

        <div class="list items-center text-pelpaygray600 mt-8">
          <div class="list-wrapper mb-5 flex items-center space-x-5">
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 7.00012L5.5 10.5001L14.5 1.50012"
                stroke="#009F49"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
            <span class="text-lg leading-6"
              >Get alerts for important updates
            </span>
          </div>

          <div class="list-wrapper mb-5 flex items-center space-x-5">
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 7.00012L5.5 10.5001L14.5 1.50012"
                stroke="#009F49"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
            <span class="text-lg leading-6"
              >Setup payment links and Look up transactions
            </span>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-left"
        data-aos-duration="1000"
        class="image-wrapper flex justify-end w-full mt-20 md:-mt-20"
      >
        <img src="../../assets/images/securedpayment.png" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
